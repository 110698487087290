<template>
  <!-- BEGIN: Content-->
  <div class="app-content content font">
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper container-xxl p-0">
      <div class="content-header row"></div>
      <div class="content-body">
        <!-- Dashboard Ecommerce Starts -->
        <section id="dashboard-ecommerce">
          <div class="row match-height">
            <!-- Medal Card -->
            <div :class="(userInfo.portal == 'false') ? 'col-xl-4 col-md-6 col-12' : 'd-none'" style="padding: 5px 5px 5px 5px">
              <div class="card custom-card card-congratulation-medal mt-top-cards">
                <div class="card-body font">
                  <h5 style="color: #00364f">
                    <strong>Congratulations</strong> <br />
                    <br />
                    {{ userInfo.name }}
                  </h5>
                  <p class="card-text font-small-3">Your Earning</p>
                  <h1 class="mb-75 mt-2 pt-50 font">
                    <a href="#" style="color: #f21300"
                      ><strong>Rs. {{ wallet.balanceAmount }}</strong></a
                    >
                  </h1>

                  <img
                    src="/vimal-assets/app-assets/images/illustration/badge.svg"
                    class="congratulation-medal"
                    alt="Medal Pic"
                  />
                </div>
              </div>
            </div>
            <!--/ Medal Card -->

            <!-- Statistics Card -->
            <div :class="(userInfo.portal == 'false') ? 'col-xl-8 col-md-6 col-12' : 'col-xl-12 col-md-12 col-12'"  class="col-xl-8 col-md-6 col-12" style="padding: 5px 5px 5px 5px">
              <div class="card custom-card card-statistics mt-top-cards">
                <div class="card-header">
                  <h4 class="card-title">Statistics</h4>
              
                </div>
                <div class="card-body statistics-body">
                  <div class="row">
                    <div class="col-xl-4 col-sm-4 col-12 mb-2 mb-xl-0">
                      <div class="d-flex flex-row">
                        <div class="avatar bg-light-primary me-2">
                          <div class="avatar-content">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="14"
                              height="14"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              class="feather feather-trending-up avatar-icon"
                            >
                              <polyline points="23 6 13.5 15.5 8.5 10.5 1 18"></polyline>
                              <polyline points="17 6 23 6 23 12"></polyline>
                            </svg>
                          </div>
                        </div>
                        <div class="my-auto">
                          <h4 class="fw-bolder mb-0">{{ dashboard.service }}</h4>
                          <p class="card-text font-small-3 mb-0">Services</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-xl-4 col-sm-4 col-12 mb-2 mb-xl-0">
                      <div class="d-flex flex-row">
                        <div class="avatar bg-light-info me-2">
                          <div class="avatar-content">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="14"
                              height="14"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              class="feather feather-user avatar-icon"
                            >
                              <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                              <circle cx="12" cy="7" r="4"></circle>
                            </svg>
                          </div>
                        </div>
                        <div class="my-auto">
                          <h4 class="fw-bolder mb-0">{{ dashboard.packages }}</h4>
                          <p class="card-text font-small-3 mb-0">Packages</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-xl-4 col-sm-4 col-12 mb-2 mb-sm-0">
                      <div class="d-flex flex-row">
                        <div class="avatar bg-light-danger me-2">
                          <div class="avatar-content">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="14"
                              height="14"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              class="feather feather-box avatar-icon"
                            >
                              <path
                                d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"
                              ></path>
                              <polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline>
                              <line x1="12" y1="22.08" x2="12" y2="12"></line>
                            </svg>
                          </div>
                        </div>
                        <div class="my-auto">
                          <h4 class="fw-bolder mb-0">{{ dashboard.client }}</h4>
                          <p class="card-text font-small-3 mb-0">Client</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--/ Statistics Card -->
          </div>

          <div class="row" style="margin-top: -28px">
            <div
              class="col-md-3 col-xl-3 col-lg-3 col-6"
              style="padding: 5px 5px 5px 5px"
            >
              <div class="card custom-card ">
                <div class="card-body"  style="    padding: 0.5rem 1rem;">
                  <div class="d-flex">
                    <div class="font-heading">
                      <font-awesome-icon icon="edit" />
                    </div>
                    &nbsp;&nbsp;
                    <div class="row">
                      <div class="col-12">
                        <h1>
                          <strong style="color: #f21000">{{
                            dashboard.clientServiceTotal
                          }}</strong>
                        </h1>
                      </div>
                      <div class="col-12">
                        <strong class="text">Projects</strong>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-md-3 col-xl-3 col-lg-3 col-6"
              style="padding: 5px 5px 5px 5px"
            >
              <div class="card custom-card">
                <div class="card-body"  style="    padding: 0.5rem 1rem;">
                  <div class="d-flex">
                    <div class="font-heading">
                      <font-awesome-icon icon="clock" />
                    </div>
                    &nbsp;&nbsp;
                    <div class="row">
                      <div class="col-12">
                        <h1>
                          <strong style="color: #f21000"
                            ><strong style="color: #f21000">{{
                              dashboard.clientServiceAssigned +
                              dashboard.clientServiceProcessed +
                              dashboard.clientServiceReject +
                              dashboard.clientServicePending +
                              dashboard.clientServiceFinalize +
                              dashboard.clientServiceHold +
                              dashboard.clientServiceUpgrade
                            }}</strong></strong
                          >
                        </h1>
                      </div>
                      <div class="col-12">
                        <strong class="text">In Process</strong>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="col-md-3 col-xl-3 col-lg-3 col-6"
              style="padding: 5px 5px 5px 5px"
            >
              <div class="card custom-card mt-top-numbers">
                <div class="card-body"  style="    padding: 0.5rem 1rem;">
                  <div class="d-flex">
                    <div class="font-heading">
                      <font-awesome-icon icon="exclamation-circle" />
                    </div>
                    &nbsp;&nbsp;
                    <div class="row">
                      <div class="col-12">
                        <h1>
                          <strong style="color: #f21000">
                            <strong style="color: #f21000">{{
                              dashboard.clientServiceCancelled
                            }}</strong></strong
                          >
                        </h1>
                      </div>
                      <div class="col-12">
                        <strong class="text">Cancelled</strong>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="col-md-3 col-xl-3 col-lg-3 col-6"
              style="padding: 5px 5px 5px 5px"
            >
              <div class="card custom-card mt-top-numbers">
                <div class="card-body"   style="    padding: 0.5rem 1rem;">
                  <div class="d-flex">
                    <div class="font-heading">
                      <font-awesome-icon icon="check-double" />
                    </div>
                    &nbsp;&nbsp;
                    <div class="row">
                      <div class="col-12">
                        <h1>
                          <strong style="color: #f21000">{{
                            dashboard.clientServiceCompleted
                          }}</strong>
                        </h1>
                      </div>
                      <div class="col-12">
                        <strong class="text">Completed</strong>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row match-height" style="margin-top: -28px">
            <div class="col-lg-4 col-12" style="padding: 5px 5px 5px 5px">
              <div class="card custom-card card-transaction mt-top-cards">
                <div class="card-body">
                  <div class="row">
                    <div class="col-8 text-truncate">
                      <h4><strong>Clients</strong></h4>
                    </div>
                    <div class="col-4 text-end">
                      <router-link
                        to="/retailer/client"
                        type="button"
                        class="btn btn-sm my-btn"
                      >
                        <small style="color: black"> View All &nbsp;&nbsp;</small>
                        <img
                          style="width: 10px"
                          src="/assets/image/right-arrow.png"
                          alt=""
                        />
                      </router-link>
                    </div>
                  </div>

                  <hr />
                  <div
                    class="transaction-item"
                    style="
                      border-bottom: 1px solid #ededed;
                      padding-bottom: 5px;
                      margin-top: -10px;
                    "
                    v-for="(retailer, index) in retailers"
                    :key="index"
                  >
                    <div class="d-flex">
                      <div class="avatar rounded float-start" style="background: white">
                        <div class="avatar-content">
                          <router-link
                            :to="`/retailer/clientprofile/${retailer.id}`"
                            type="button"
                          >
                            <img
                              style="width: 30px; height: 30px"
                              :src="profilePhotoFinder(retailer, 'client')"
                              alt=""
                            />
                          </router-link>
                        </div>
                      </div>
                      <div class="transaction-percentage" style="line-height: 1">
                        <h6 class="transaction-title">
                          <strong style="color: #00364f">
                            {{ retailer.fname }} {{ retailer.lname }}
                          </strong>
                        </h6>
                        <small> {{ retailer.code }}</small>
                      </div>
                    </div>
                    <div class="text-success">Active</div>
                    <!-- <div v-else class="text-danger">InActive</div> -->
                  </div>
                </div>
              </div>
            </div>

            <!-- Plateform Admins Card -->
            <div class="col-lg-4 col-12" style="padding: 5px 5px 5px 5px">
              <div class="card custom-card card-transaction mt-top-cards">
                <div class="card-body">
                  <div class="row">
                    <div class="col-8 text-truncate">
                      <h4><strong>Tickets</strong></h4>
                    </div>
                    <div class="col-4 text-end">
                      <router-link
                        to="/retailer/ticket"
                        type="button"
                        class="btn btn-sm my-btn"
                      >
                        <small style="color: black"> View All &nbsp;&nbsp;</small>
                        <img
                          style="width: 10px"
                          src="/assets/image/right-arrow.png"
                          alt=""
                        />
                      </router-link>
                    </div>
                  </div>

                  <hr />
                  <div
                    class="transaction-item"
                    v-for="(ticket, index) in tickets"
                    :key="index"
                  >
                    <div class="d-flex">
                      <div class="transaction-percentage">
                        <h6 class="transaction-title" style="padding-bottom: 10px">
                          <strong v-if="ticket.department" style="color: #00364f">{{
                            ticket.department.name
                          }}</strong>
                          <small> ( {{ ticket.subject }}) </small>
                        </h6>
                        <small
                          >{{ ticket.ticketNo }} ({{
                            dateTime(ticket.updated_at)
                          }}
                          )</small
                        >
                      </div>
                    </div>
                    <div>
                      <p
                        v-if="ticket.status == 'open'"
                        class="btn btn-sm"
                        data-v-4a972433=""
                        style="
                          width: 60px;
                          background-color: green;
                          color: white;
                          box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 5px 0px;
                          border-radius: 50px;
                          font-size: 10px;
                          padding: 3px;
                          margin-top: 10px;
                        "
                      >
                        <b data-v-4a972433="">open</b>
                      </p>
                      <p
                        v-else
                        class="btn btn-sm"
                        data-v-4a972433=""
                        style="
                          width: 60px;
                          background-color: #f21000;
                          color: white;
                          box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 5px 0px;
                          border-radius: 50px;
                          font-size: 10px;
                          padding: 3px;
                          margin-top: 10px;
                        "
                      >
                        <b data-v-4a972433="">close</b>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--/ Plateform Admins Card -->

            <!-- Plateform Admins Card -->
            <div class="col-lg-4 col-12" style="padding: 5px 5px 5px 5px">
              <div class="card custom-card card-transaction mt-top-cards">
                <div class="card-body">
                  <div class="row">
                    <div class="col-8 text-truncate">
                      <h4><strong>Invoice</strong></h4>
                    </div>
                    <div class="col-4 text-end">
                      <router-link
                        to="/retailer/invoice"
                        type="button"
                        class="btn btn-sm my-btn"
                      >
                        <small style="color: black"> View All &nbsp;&nbsp;</small>
                        <img
                          style="width: 10px"
                          src="/assets/image/right-arrow.png"
                          alt=""
                        />
                      </router-link>
                    </div>
                  </div>

                  <hr />
                  <div
                    class="transaction-item"
                    v-for="(challan, index) in invoices"
                    :key="index"
                  >
                    <div class="d-flex">
                      <div class="transaction-percentage">
                        <h6 class="transaction-title" style="padding-bottom: 10px">
                          <strong style="color: #00364f" v-if="challan.client">
                            {{ challan.client.fname }} {{ challan.client.lname }}</strong
                          ><small style="color: #f21000"> <i>(Due:   <span v-if="challan">{{
                                  getBallance(challan)
                                }}</span>)</i> </small>
                        </h6>

                        <small>
                          {{ challan.clientServiceNo }} ({{
                            dateTime(challan.date)
                          }}
                          )</small
                        >
                      </div>
                    </div>
                    <div v-if="challan.service">
                      <strong style="color: #00364f">Invoice: </strong>
                      <small
                        style="color: #f21000"
                        v-if="challan.service.status == 'true'"
                      >
                        {{ challan.serviceCharge + challan.serviceChargeGst  }}</small
                      >
                      <small style="color: green" v-else>
                        {{ challan.serviceCharge + challan.serviceChargeGst}}</small
                      >

                      <!-- <p
                        v-if="challan.service.status == 'true'"
                        class="text-white btn btn-sm"
                        style="
                          background-color: green;
                          width: 70px;
                          box-shadow: 2px 2px 5px gray;
                          border-radius: 50px;
                          font-size: 10px;
                          padding-top: 5px;
                          padding-bottom: 5px;
                          padding-left: 7px;
                          padding-right: 7px;
                          margin-top: 5px;
                          margin-bottom: 5px;
                        "
                      >
                        <b> Active</b>
                      </p>
                      <p
                        v-else
                        class="text-white btn btn-sm"
                        style="
                          background-color: #f21300;
                          width: 70px;
                          box-shadow: 2px 2px 5px gray;
                          border-radius: 50px;
                          font-size: 10px;
                          padding-top: 5px;
                          padding-bottom: 5px;
                          padding-left: 7px;
                          padding-right: 7px;
                          margin-top: 5px;
                          margin-bottom: 5px;
                        "
                      >
                        <b> InActive</b>
                      </p> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--/ Plateform Admins Card -->
          </div>

          <!-- start new -->
        </section>
        <!-- Dashboard Ecommerce ends -->
      </div>
    </div>
  </div>
  <!-- END: Content-->
</template>

<script>
import moment from "moment";
export default {
  name: "SuperAdminHome",
  data() {
    return {
      adminServices: [],
      usersCount: {},
      dashboard: {},
      wallet: {},
      userInfo: {},
      plateformAdmins: [],
      frenchformAdmins: [],
      tickets: [],
      withdrawls: [],
      invoices: [],
      masterDistributers: [],
      distributers: [],
      departMents: [],
      retailers: [],
    };
  },
  methods: {
    loadDepartments() {
      this.loading = true;
      this.$axios
        .get(`retailer/department?all=all`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res);
          this.departMents = res.data.data;
        })
        .finally(() => (this.loading = false));
    },
    loadDashboard() {
      this.loading = true;
      this.$axios
        .get(`retailer/dashboard`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res);
          this.dashboard = res.data;
        })
        .finally(() => (this.loading = false));
    },

    dateTime(value) {
      return moment(value).format("DD-MM-YYYY");
    },
    loadWallet() {
      this.loading = true;
      this.$axios
        .get(
          `retailer/wallet`,

          {
            headers: { Authorization: "Bearer " + localStorage.accessToken },
          }
        )
        .then((res) => {
          console.log(res);
          this.wallet = res.data.data;
        })
        .finally(() => (this.loading = false));
    },

    adminlogofinder(admin) {
      var photo = "";
      if (admin.logo != null) {
        photo = this.$store.state.imgUrl + "/admin/" + admin.id + "/thumbs/" + admin.logo;
        return photo;
      } else {
        photo = this.$store.state.placeholderImg;
        return photo;
      }
    },
    loadTickets() {
      this.loading = true;
      this.$axios
        .get(`retailer/clientserviceticket?per_page=5`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res);
          this.tickets = res.data.data.data;
        })
        .finally(() => (this.loading = false));
    },
    getBallance(client_service) {
      var bal = 0;
      var payments = client_service.client_payment;
      for (var i = 0; i < payments.length; i++) {
        bal += payments[i].amt;
      }
      bal = client_service.serviceCharge + client_service.serviceChargeGst - bal;
      return bal;
    },
    loadInvoices() {
      this.loading = true;
      this.$axios
        .get(`retailer/clientservice?per_page=5`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res);
          this.invoices = res.data.data.data;
        })
        .finally(() => (this.loading = false));
    },

    loadClients() {
      this.loading = true;
      this.$axios
        .get(`retailer/client?per_page=5`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res);
          this.retailers = res.data.data.data;
        })
        .finally(() => (this.loading = false));
    },
    profilePhotoFinder(masterDistributer, type) {
      var photo;
      if (masterDistributer.photo != null) {
        photo =
          this.$store.state.imgUrl +
          "/" +
          type +
          "/" +
          masterDistributer.id +
          "/thumbs/" +
          masterDistributer.photo;
        return photo;
      } else {
        photo = this.$store.state.placeholderImg;
        return photo;
      }
    },
    loadPageTitle() {
      document.title = this.$store.state.retailerPageTitles.dashboard
    }
  },


  created() {
    if (
      this.$route.meta.redirectIfLoggedIn == "true" &&
      localStorage.getItem("accessToken") == null
    ) {
      this.$router.push({ name: "Login" });
    } else {    
      this.loadPageTitle()
      this.loadTickets();
      this.loadClients();
      this.loadInvoices();
      this.loadWallet();
      this.loadDepartments();
      this.loadDashboard();
      this.userInfo = JSON.parse(localStorage.getItem("userData"));
    }
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");
.font {
  font-family: "Poppins", sans-serif;
}

.bg-1-project {
}

/* .bg-1-admin {
  background: rgb(255, 255, 255);
  background: radial-gradient(circle, rgba(255, 255, 255, 0) 0%, rgba(0, 54, 79, 1) 100%);
} */

/* .bg-2-admin {
  background: rgb(255, 255, 255);
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 0) 0%,
    rgba(242, 16, 0, 1) 100%
  );
} */

.bg-light-success {
  background: white !important;
}
.my-btn {
  color: black;
  transition: 0.5s;
  margin-right: -20px;
}
.my-btn:hover {
  background: rgba(212, 206, 206, 0.438);
}

.table-scroll {
  overflow-y: auto;
}

.form-select {
  border: 1px solid #00364f;
}

.form-checkbox {
  width: 25px;
  margin-top: 5px;
}

nav ul {
  height: 300px;
  width: 100%;
}

nav ul {
  overflow: hidden;
  overflow-y: scroll;
}

div.scrollmenu {
  overflow: auto;
  white-space: nowrap;
}

div.scrollmenu a {
  display: inline-block;
  color: white;
  font-family: "Poppins", sans-serif;
  color: #00364f;
  text-decoration: none;
}

div.scrollmenu a .card {
  height: 50%;
  width: 40%;
}

.come-from-right .modal-dialog {
  position: fixed;
  top: 0px;
  right: 0px;
  margin: auto;
  width: 300px;
  height: 100%;
  transform: translate3d(0%, 0, 0);
  transition: opacity 0.3s linear, left 0.3s ease-out;
}

.come-from-right .modal-content {
  height: 100%;
  overflow-y: auto;
  border-radius: 0px;
}

.come-from-right .modal-body {
  padding: 15px 15px 80px;
}
.custom-card {
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px rgba(206, 205, 205, 0.555);
  background-color: white;
}
@media screen and (max-width: 767px) {
  .mt-top-numbers {
    margin-top: -26px;
  }
  .mt-top-cards {
    margin-top: -27px;
  }
}

.h1-heading {
  color: red;
  font-size: 3vw;
}

.font-heading {
  font-family: "Poppins", sans-serif;
  font-size: 3rem;
  color: rgb(216, 209, 209);
}

.text {
  font-family: "Poppins", sans-serif;
  color: #00364f;
}

.btns {
  display: inline-flex;
}

.tr-head {
  font-size: 11pt;
  margin-left: 25px;
  border-radius: 26px;
  background-color: #cdcdcd;
}

.table-scroll {
  overflow-y: auto;
}
.page-item.active .page-link {
  z-index: 3;
  border-radius: 5rem;
  background-color: #00364f;
  color: #fff !important;
  font-weight: 600;
}
h4[data-v-69e5a8da] {
  color: #fff;
  font-family: poppins, Sans-serif;
  font-weight: 600;
  font-size: 25;
}
input:checked {
  /* background-color: #7367f0; */
  border-color: #ff000c;
  border-width: 3px;
}
.form-check-input:checked {
  background-color: #fdfdfd;
  /* border-color: #7367f0; */
}
</style>
